.container {
  overflow: hidden;
}
.p-id{
  color: #101710;
}

.naveg {
  position: sticky;
  top: 0;
  padding: 10px 0;
  z-index: 1000;
}

.icono-wp-white {
  width: 16px;
  height: 16px; 
  fill: #ffffff; 
  margin-right: 8px;
}



.icono-flecha {
  width: 16px;
  height: 16px; 
  fill: #28a745; 
  margin-right: 8px; 
}

.escudo{
  width: 36px;
  height: 36px; 
  margin-bottom: 14px;
  align-self: center;
  fill: #28a745; 
  margin-right: 8px; 
}

/********************** header *****************************/
.collapse-font{
  display: flex; 
  flex-wrap: wrap; 
  column-gap: 5vw; 
  justify-content: center;
  font-size: 25px;
}
@media (max-width: 500px) {
  .collapse-font{
    display: flex; 
    flex-wrap: wrap; 
    column-gap: 2vw; 
    justify-content: center;
    font-size: 15px;
  }
}

.custom-color-nav .nav-pills .nav-link.active {
  background-color: #218838;
}

.custom-color-nav{
  background: linear-gradient(to right, #111c12, #0f6e0d);
}

.num-responsive {
  font-size: 2rem; 
}

  @media (max-width: 1403px) {
    .num-responsive {
      font-size: 1.7rem; 
    }
  }
  @media (max-width: 1365px) {
    .num-responsive {
      font-size: 1.6rem; 
    }
  }
  @media (max-width: 1349px) {
    .num-responsive {
      font-size: 1.5rem; 
    }
  }

  @media (max-width: 1250px) {
    .num-responsive  {
      font-size: 1.4rem; 
    }
  }
  @media (max-width: 1105px) {
    .num-responsive  {
      font-size: 1.2rem; 
    }
  }

  @media (max-width: 500px) {
    .num-responsive  {
      font-size: 1rem; 
    }
  }

.wp-button-container{}
  @media (max-width: 576px) {
    .wp-button-container  {
      justify-content: center; 
    }
  }

.icono-wp-white-header {
  width: 46px;
  height: 46px; 
  fill: #ffffff; 
  margin-right: 5px; 
}
@media (max-width: 500px){
  .icono-wp-white-header {
    width: 0px;
    height: 26px; 
    fill: #ffffff; 
    }
}

  @media (max-width: 1260px) {
    .icono-wp-white-header  {
      width: 2.4rem; 
    }
  }
  @media (max-width: 1105px) {
    .icono-wp-white-header  {
      width: 2.1rem; 
    }
  }
  @media (max-width: 1105px) {
    .icono-wp-white-header  {
      width: 1.9rem; 
    }
  }

.gap-responsive{
  gap: 44px;
}
  @media (max-width: 1500px) {
    .gap-responsive  {
      gap: 1rem; 
    }
  }
  @media (max-width: 1244px) {
    .gap-responsive  {
      gap: 0.9rem; 
      font-size: 1.6rem;
    }
  }
  @media (max-width: 1189px) {
    .gap-responsive  {
      gap: 0.9rem; 
      font-size: 1.45rem;
    }
  }
  @media (max-width: 1139px) {
    .gap-responsive  {
      gap: 0.9rem;
      font-size: 1.35rem;
    }
  }
  @media (max-width: 1109px) {
    .gap-responsive  {
      gap: 0.65rem; 
      font-size: 1.35rem;
    }
  }

  @media (max-width: 1083px) {
    .gap-responsive  {
      gap: 0.60rem; 
      font-size: 1.35rem;
    }
  }

  @media (max-width: 1079px) {
    .gap-responsive  {
      gap: 0.50rem; 
      font-size: 1.25rem;
    }
  }

  @media (max-width: 1015px) {
    .gap-responsive  {
      gap: 0.40rem; 
      font-size: 1.20rem;
    }
  }
  @media (max-width: 993px) {
    .gap-responsive  {
      gap: 0.36rem;
      font-size: 1.20rem;
    }
  }
  

.resposive-nav{}

.cotiza-responsive{}
  @media (max-width: 1260px) {
    .cotiza-responsive {
      font-size: 0.9rem; 
    }
  }
  @media (max-width: 500px){
    .cotiza-responsive {
      font-size: 0.6rem; 
    }
  }

    
.btn-xlg{
  font-size: 2.5rem;
  color: white;
}


.buttonNav {
  margin: 0; 
  padding: 0;
  font-weight: bold;
}

.btn-orange {
  background-color: orange;
  border-color: orange;}
  
@media (max-width: 500px){
  .btn-orange {
    background-color: orange;
    border-color: orange;
    height: 3rem;
  }
}
.btn-orange:hover {
  background-color: darkorange; 
  border-color: darkorange;
}

@media (max-width: 768px) {
  .btn-orange {
    font-size: 14px; 
  }
}

/***************************** carusel *********************************/

.carousel-container-clientes {
  height: 300px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .carousel-container-clientes {
    height: 100px; 
  }
}


.carousel-track {
  display: flex;
  justify-content: space-around;
  align-items: center;
  
}

.carousel-image-quienes {
  max-width: 100%;
  height: auto;
  border-radius: 10px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.carousel-image-quienes {
  max-height: 350px;
}

@media (max-width: 768px) {
  .carousel-image-quienes {
    max-width: 70%;
    max-height: 120px;
  }

  .carousel-container-clientes {
    padding: 5px;
  }
}

@media (max-width: 480px) {
  .carousel-image-quienes {
    max-width: 90%;
    max-height: 100px;
  }
}


/******************************* inicio***************************/
.logo-inicio{
  max-width: 100%;
  height: auto;
  display: block;
}

.boxs{}
  @media   (max-width: 575px) {
    .boxs {
      justify-content: center; 
    }
  }



.box{}
  @media (max-width: 537px) {
    .box {
     height: 80px; 
    }
  }
  @media (max-width: 513px) {
    .box {
      height: 70px; 
    }
  }
  @media (max-width: 437px) {
    .box {
      height: 60px; 
    }
  }
  @media (max-width: 397px) {
    .box {
      height: 50px; 
    }
  }
  @media (max-width: 339px) {
    .box {
     height: 48px; 
    }
  }

.div-con-fondo {
  /* ... */
  background: url('assets/Video-de-Lucas-Armat-Mkt.mp4') no-repeat center center fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-animation: animate 30s linear infinite;
  -moz-animation: animate 30s linear infinite;
  animation: animate 30s linear infinite;
}

@-webkit-keyframes animate {
  0% { background-position: 0% 50% }
  100% { background-position: 100% 50% }
}

@-moz-keyframes animate {
  0% { background-position: 0% 50% }
  100% { background-position: 100% 50% }
}

@keyframes animate {
  0% { background-position: 0% 50% }
  100% { background-position: 100% 50% }
}


.card-text { /************************/
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  margin-bottom: 10px; 
}


.divider-inicio {
  border-top: 2px solid rgb(255, 255, 255); 
  margin-bottom: 10px;
}

/*************************************/
.security-section {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.overlay2 {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}


/************************************/

.text-content {
  color: #0e8b03; 
  margin: 0 auto; 
  margin-bottom: 20px
}

.text-content h3 {
  font-weight: bold;
  margin-bottom: 20px;
}

.text-content p {
  font-size: 18px;
  margin-bottom: 30px;
  color: white;
  font-family: 'Courier New', Courier, monospace;
}

.form-content {
  background-color: rgba(0, 0, 0, 0.7); 
  padding: 40px;
  border-radius: 8px;
  max-width: 500px;
}

.form-content h2 {
  color: #d4e157;
  margin-bottom: 20px;
}

.submit-button {
  width: 100%;
  padding: 10px 0;
  font-weight: bold;
}


.overlay-text {
  color: #32a852; 
  padding: 30px;
  max-width: 400px;
  margin-left: 50px; 
}

.contact-button {
  margin-top: 20px;
  border: 2px solid #32a852;
  color: #32a852;
  background: transparent;
  transition: all 0.3s ease;
}


.form-container {
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.nosotros-section {
  background-color: #101710; 
  color: #FFFFFF;
  
}

.divider-nosotros {
  border-top: 2px solid white; 
  width: 1000px;
  margin-bottom: 10px auto;
  display: block;
}

.items-aspiramos{
  font-size: 20px;
}

.section-title {
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: #0e8b03;
}

.left-text {
  padding-left: 100px; 
  max-width: 600px;    
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
  font-weight: bold;
}

button {
  margin-top: 20px;
}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}



.card-custom {
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  max-width: 250px; 
  height: 400px; 
}

  @media (max-width: 1290px) {
    .card-custom {
      border-radius: 15px;
    overflow: hidden;
    position: relative;
    max-width: 200px; 
    height: 400px; 
    }
  }

  @media (max-width: 1066px) {
    .card-custom {
      border-radius: 15px;
    overflow: hidden;
    position: relative;
    max-width: 180px; 
    height: 400px; 
    }
  }
  @media (max-width: 989px) {
    .card-custom {
      border-radius: 15px;
    overflow: hidden;
    position: relative;
    max-width: 250px; 
    height: 400px; 
    }
  }
  @media (max-width: 891px) {
    .card-custom {
      border-radius: 15px;
    overflow: hidden;
    position: relative;
    max-width: 200px; 
    height: 400px; 
    }
  }
  @media (max-width: 535px) {
    .card-custom {
      border-radius: 15px;
    overflow: hidden;
    position: relative;
    max-width: 250px; 
    height: 400px; 
    }
  }



.card-img2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
  background-color: rgb(0, 0, 0); 
  
}

.card-content {
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-title,
.card-text {
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  margin-bottom: 10px;
}

.card-title {
  font-size: 1.1rem;
}

.card-text {
  font-size: 0.9rem;
  padding: 0 10px; 
}



/*********************** Footer ***********************/
.footer-custom {
  background-color: #101710; 
  padding: 20px 0;
}



.footer-logo {
  max-width: 150px; 
}

.text-white {
  color: #fff; 
}

.text-left, .text-right {
  font-size: 14px; 
}

@media (max-width: 768px) {
  .text-left, .text-right {
    text-align: center; 
    margin-top: 10px;
  }
}



/***********************  Contacto ***********************/
.btn-no-fill:hover {
  background-color: transparent !important;
  color: inherit !important; 
  border-color: inherit !important; 
}

.img-pilar{
  max-width: 500px;
  max-height: 700px;
  opacity: 0.5;
  border-radius: 8px;
}



.icono-wp {
  width: 16px;
  height: 16px; 
  fill: #28a745; 
  margin-right: 8px; 
}


.header-section-contacto {
  background-image: url('./assets/Oficina-banner.jpg'); 
  top: 0;
  left: 0;
  width: 100%;
  height: 220px; 
  background-size: cover;
  background-position: center;
  z-index: 1;   
}

.overlay { /**********************************/
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); 
  display: flex;
  justify-content: center;
}

.header-title {
  color: white;
  font-size: 36px; 
  font-weight: bold;
  text-align: center;
  z-index: 1;
  height: 220px; 
  background-color: rgba(0, 0, 0, 0.4); 
  align-content: center;
}

.divider {
  border-top: 2px solid #0f9403; 
  width: 200px;
  margin-bottom: 10px;
}

.h2 {  
  font-size: 24px; 
  font-weight: bold;
}

.p { 
  font-size: 16px; 
  color: #333; 
}


/*********************** Servicios ***********************/
.header-section-servicios {
  background-image: url('./assets/baner\ servicios\ 2-banner.jpg'); 
  top: 0;
  left: 0;
  width: 100%;
  height: 220px; 
  background-size:cover;
  background-position: center;
  z-index: 1;
}

.container-ser {}
@media (max-width: 991px) {
  .container-ser {
    width: 100%;
    max-width: none;
  }}
  @media (max-width: 767px) {
    .container-ser {
      width: 80%;
      max-width: none;
    }}

h2 {
  font-size: 36px;
  font-weight: bold;
}

p {
  font-size: 16px;
  color: #555; 
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
  font-size: 16px;
  color: #2c3e50; 
}

.img-fluid {
  max-height: 300px; 
  object-fit: cover;
  border-radius: 8px; 
}

.img-logo {
  max-height: 300px; 
  object-fit: cover;
  border-radius: 8px; 
}
  @media (max-width: 322px) {
    .img-logo  {
      width: 150px;
    }
  }



.divider2 {
  border-top: 2px solid #0f9403; 
  margin-bottom: 10px;
}

/*
h3 {
  font-size: 28px;
  font-weight: bold;
  color: #2c3e50; 
}
  */

p {
  font-size: 16px;
  color: #555; 
}

.security-list {
  list-style-type: none;
  padding: 0;
}

.security-list li {
  margin-bottom: 10px;
  font-size: 16px;
  color: #2c3e50;
}




.image-container {
  display: flex;
  flex-direction: row; 
  gap: 10px; 
  max-width: 100%; 
}

.image-container-d {
  display: flex;
  flex-direction: row; 
  gap: 10px; 
  max-width: 100%; 
  height: 350px;  
}


.img-s {
  border-radius: 10px;
  max-width: 100%; 
  max-height: 500px; 
  object-fit: auto;
}

.img-s-dispos {
  object-fit: contain;
  border-radius: 8px;
  width: 900px;
  height: 300px;
}

@media (max-width: 768px) {
  .image-container {
    flex-direction: row;
    gap: 5px; 
  }
}

.mb-3 {
  margin-bottom: 20px; 
}

.small-image {
  width: 100%;
  height: 150px; 
  object-fit: cover; 
  border-radius: 4px;
}

.large-image {
  width: 100%;
  height: auto; 
}


/***********************Quienes ***********************/
.container-responsive-iframe{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
/***********
  @media (max-width: 500px){
    .container-responsive-iframe{
      padding-top: 100%
    }
  }
*****/
.responsive-iframe{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.card-quienes {
  border-radius: 10px;
  overflow: hidden;
  background-color: #f8f9fa; 
}

.card-title-quienes {
  font-weight: bold;
  font-size: 1.5rem;
  color: #2a7a2a; 
}

.card-text-quienes {
  font-size: 1rem;
  color: #343a40;
}

.nav-tabs .nav-link.active {
  background-color: #2a7a2a; 
  color: white;
  border: none;
}

.nav-tabs .nav-link {
  font-size: 1.2rem;
  color: #2a7a2a;
}

.card-body-quienes {
  padding: 20px;
}

.header-section-quienes {
  background-image: url('./assets/pagina\ web\ 2/Banner-quiene-somos.jpg'); 
  top: 0;
  left: 0;
  width: 100%;
  height: 220px; 
  background-position: 60% 56%;
  z-index: 1;
  background-size: cover;
}

.banner-title {
  margin-top: 230px;
  color: white;
  font-size: 36px; 
  font-weight: bold;
  text-align: center;
  z-index: 1; 
}

.company-info-section {
  background-color: #101710; 
  color: #e0e0e0; 
}

.header-box {
  background-color: hsl(111, 94%, 12%); 
  padding: 10px 20px;
  display: inline-block; 
}

.company-name {
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin: 0;
}

.subtitle {
  font-size: 12px;
  color: #ebe2e2; 
  margin: 0;
}

.description {
  font-size: 25px;
  line-height: 1.6;
  margin-top: 20px;
  color: white;
}

  @media (max-width: 1800px) {
    .description  {
      font-size: 1.2rem;
    }
  }
  @media (max-width: 767px) {
    .description  {
      font-size: 1.5rem;
    }
  }











.description-box {
  background-color: #fff; 
  padding: 15px;
  color: #333; 
  border-radius: 5px;
  margin-top: 10px;
}


.overflow{
  overflow-x: hidden;
}

.consulta-section {
  background-image: url('./assets/monitoreo-3.jpg'); 
  background-size: cover;
  background-position: center;
  padding: 50px;
  margin-left: calc(-50vw + 50%);
  overflow-x: hidden;
  max-width: cover;
}

.consulta-button {
  background-color: #28a745; 
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.consulta-button:hover {
  background-color: #218838; 
}


.clientes-logo {
  width: 250px; 
  height: auto; 
  object-fit: contain; 
  margin: 0 10px; 
}

.clientes-section {
  background-color: #ffffff; 
}

.clientes-logo {
  max-width: 100%; 
  height: auto; 
  display: block; 
  padding: 10px; 
}














.slider-c{
  height: 250px;
  margin: auto;
  position: relative;
  width: 800px;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slide-track{
  display: flex;
  width: calc(250px * 10);
  animation: scroll 40s linear infinite;
}

.slide-track:hover{
  animation-play-state: paused;
}


@keyframes scroll {

  0% {
    transform: translateX(0);
  }
  100%{
    transform: translateX(calc(-250px * 5));
  }
  
}

.slide-c{
  height: 200px;
  width: 250px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}

.img-c{
  object-fit: contain;
  border-radius: 10px;
  max-width: 100%; 
  height: 450px;    
  transition: transform 1s;
}

.img-c:hover{
  transform: translateZ(20px);
}



